<template>
  <core-menu>
    <region-menu-header name="Results" icon="mdi-folder-open" />
    <v-divider />
    <v-divider />
    <v-list>
      <v-list-item-group v-model="selectedItem">
        <v-list-item v-if="showCorrelation" value="correlation">
          <v-list-item-title class="subtitle-2">
            {{ $t("correlationTool.toolName") }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="showCorrelationDischarge"
          value="correlationDischarge"
        >
          <v-list-item-title class="subtitle-2">
            {{ $t("correlationToolDischarge.toolName") }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item v-if="showSedimentStations" value="sedimentStations">
          <v-list-item-title class="subtitle-2">
            {{ $t("sedimentTool.toolName") }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item v-if="showInSituLayer" value="inSitu">
          <v-list-item-title class="subtitle-2">
            {{ $t("correlationTool.inSituStation") }}
          </v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </core-menu>
</template>

<script>
import CoreMenu from "@/core/components/menu/CoreMenu";
import RegionMenuHeader from "@/core/components/menu/RegionMenuHeader";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "SedimentResultsMenu",
  components: { CoreMenu, RegionMenuHeader },
  computed: {
    ...mapState("analyse", [
      "correlationFormulaItems",
      "correlationDischargeFormulaItems",
      "sedimentStations"
    ]),
    ...mapState("userdata", ["selectedTopic"]),
    ...mapGetters("vector", ["inSituLayer"]),
    showCorrelation() {
      return Boolean(this.correlationFormulaItems.length);
    },
    showCorrelationDischarge() {
      return Boolean(this.correlationDischargeFormulaItems.length);
    },
    showSedimentStations() {
      return Boolean(this.sedimentStations.length);
    },
    showInSituLayer() {
      return Boolean(this.inSituLayer.length);
    },
    selectedItem: {
      get() {
        return this.selectedTopic;
      },
      set(val) {
        if (val === undefined) {
          return; // avoid unselecting when double clicking on an item
        }
        this.$store.commit("userdata/SET_SELECTED_TOPIC", val);
      }
    }
  },
  methods: {
    ...mapActions("analyse", [
      "fetchCorrelationFormulas",
      "fetchCorrelationDischargeFormulas",
      "fetchSedimentStations"
    ])
  },
  async created() {
    await this.fetchCorrelationFormulas();
    await this.fetchCorrelationDischargeFormulas();
    await this.fetchSedimentStations();

    if (this.showCorrelation === true) {
      this.selectedItem = "correlation";
    } else if (this.showCorrelationDischarge === true) {
      this.selectedItem = "correlationDischarge";
    } else if (this.showInSituLayer === true) {
      this.selectedItem = "inSitu";
    } else if (this.showSedimentStations === true) {
      this.selectedItem = "sedimentStations";
    }
  }
};
</script>

<style scoped></style>
